import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import CarouselHomePage from "../components/carouselHomepage"
import ServicesTime from "../components/servicesTime"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Image from "../components/image"
import { useProfilePhoto } from "../custom-hooks/useProfilePhoto"

const IndexPage = () => {
  const { blog } = useStaticQuery(graphql`
    {
      blog: allFile(filter: { relativeDirectory: { eq: "blog" } }) {
        edges {
          node {
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <SEO title="Welcome" />
      <Layout>
        <div className="container my-5">
          <CarouselHomePage />
          <div className="row my-5">
            <div className="col-12 col-md-9">
              <Link
                to="/blog-template"
                className="text-decoration-none text-dark"
              >
                <Image data={blog.edges[0].node} />
                <div className="my-3" />
                <h1>{blog.edges[0].node.name}</h1>
                <div className="d-flex align-items-center">
                  <Img
                    fixed={useProfilePhoto()}
                    style={{ maxWidth: "48px", maxHeight: "48px" }}
                  />
                  <div>
                    <p className="m-0 ml-2 text-muted">
                      Rm. Lucky · {new Date().toDateString()}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-md-3 shadow-sm rounded mt-3 mt-md-0 py-3">
              <ServicesTime />
            </div>
          </div>
          <div className="row mt-5">
            {blog.edges.slice(1).map(renungan => {
              return (
                <div className="col-12 col-md-6 col-lg-4 my-3">
                  <Link
                    to="/blog-template"
                    className="text-decoration-none text-dark"
                  >
                    <Image data={renungan.node} />
                    <div className="my-2" />
                    <p className="text-muted m-0">Kategori</p>
                    <h3>{renungan.node.name}</h3>
                    <p className="text-muted ">Sub Judul</p>
                    <div className="d-flex align-items-center">
                      <Img
                        fixed={useProfilePhoto()}
                        style={{ maxWidth: "24px", maxHeight: "24px" }}
                      />
                      <div>
                        <p className="m-0 ml-2 text-muted small">
                          Rm. Lucky · {new Date().toDateString()}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default IndexPage
