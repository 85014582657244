import { useStaticQuery, graphql } from "gatsby"

export const useProfilePhoto = () => {
  const { profilePhoto } = useStaticQuery(graphql`
    {
      profilePhoto: file(relativePath: { eq: "profilePicture/Rm Lucky.png" }) {
        childImageSharp {
          fixed(width: 96, height: 96) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return profilePhoto.childImageSharp.fixed
}
