import React from "react"
const ServicesTime = () => {
  return (
    <React.Fragment>
      <h2>Services Time</h2>

      <h6>Senin - Sabtu</h6>
      <p>06.00</p>
      <h6>Jumat Pertama</h6>
      <p>06.00 | 12.00 | 19.30</p>
      <h6>Sabtu</h6>
      <p>17.00</p>
      <h6>Minggu</h6>
      <p>06.30 | 09.00 | 17.00</p>

      <h2>Adorasi Ekaristi</h2>
      <h6>24 Jam</h6>
      <p>
        Adorasi Sakramen Maha Kudus dapat dilaksanakan setiap saat (24 jam) di
        Kapel SanMaRe
      </p>
    </React.Fragment>
  )
}

export default ServicesTime
