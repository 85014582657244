import React from "react"
import { Carousel } from "react-responsive-carousel"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const CarouselHomePage = () => {
  let { temporaryPengumuman } = useStaticQuery(graphql`
    {
      temporaryPengumuman: allFile(
        filter: { relativeDirectory: { eq: "pengumuman" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <Carousel
        showThumbs={false}
        infiniteLoop
        autoPlay
        showArrows
        showStatus={false}
        useKeyboardArrows
        showArrows={true}
        emulateTouch
      >
        {temporaryPengumuman.edges.map(image => {
          return (
            <div
              className="position-relative w-100"
              style={{ paddingTop: "56.25%" }}
            >
              <Img
                className="position-absolute w-100"
                style={{
                  top: "0",
                  left: "0",
                  height: "100%",
                }}
                fluid={image.node.childImageSharp.fluid}
              />
              <div
                className="position-absolute w-100 overlay-legend"
                style={{ bottom: "0", right: "0", height: "8rem" }}
              >
                <h2 className="legend">{image.node.name}</h2>
              </div>
            </div>
          )
        })}
      </Carousel>
    </React.Fragment>
  )
}

export default CarouselHomePage
