import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const Image = ({ data }) => {
  const { placeholder } = useStaticQuery(graphql`
    {
      placeholder: file(relativePath: { eq: "santa-maria-regina-icon.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <div
        className="image position-relative w-100"
        style={{ paddingTop: "56.25%" }}
      >
        {data ? (
          <Img
            className="position-absolute w-100"
            style={{ top: "0", left: "0", height: "100%" }}
            fluid={data.childImageSharp.fluid}
          />
        ) : (
          <Img
            className="position-absolute w-100"
            style={{
              top: "0",
              left: "0",
              height: "100%",
            }}
            fluid={placeholder.childImageSharp.fluid}
          />
        )}
      </div>
    </React.Fragment>
  )
}

export default Image
