import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"

const Footer = () => {
  const { logo } = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "santa-maria-regina-icon.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <div className="bg-dark py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4">
              <Link className="navbar-brand text-dark" to="/">
                <Img fixed={logo.childImageSharp.fixed} />
                <h6 className="text-light text-wrap">
                  Gereja Santa Maria Regina Paroki Bintaro Jaya
                </h6>
              </Link>

              <p className="small text-muted">
                Jl. M.H. Thamrin Kav. B2 No.3 CBD Bintaro Jaya Sektor 7, Pondok
                Jaya, Pondok Aren, Pd. Jaya, Kec. Pd. Aren, Kota Tangerang
                Selatan, Banten 15220
              </p>
              <p className="small text-muted">Telp. 021-745 9715, 745 9716</p>
              <p className="small text-muted">Fax. 021-745 9717</p>
              <p className="small text-muted">
                sekretariat@parokisanmare.or.id
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="py-3" style={{ backgroundColor: "#111111" }}>
        <div className="container text-center">
          <p className="m-0 small text-light">
            ⓒ{new Date().getFullYear().toString()} Gereja Santa Maria Regina
            Paroki Bintaro Jaya. All Rights Reserved.
          </p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Footer
