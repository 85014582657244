import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { MdMenu, MdSearch } from "react-icons/md"

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false)
  const { logo } = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "santa-maria-regina-icon.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-lg bg-light navbar-light fixed-top shadow-sm">
        <div className="container">
          <Link
            className="navbar-brand d-flex align-items-center font-weight-bolder text-uppercase"
            to="/"
          >
            <Img fixed={logo.childImageSharp.fixed} className="mr-2" />
            Sanmareku
          </Link>
          <button
            className="navbar-toggler border-0"
            type="button"
            onClick={() => setShowMenu(!showMenu)}
          >
            <MdMenu size={"1.7rem"} />
          </button>

          <div className={`collapse navbar-collapse ${showMenu && `show`}`}>
            <ul className="navbar-nav ml-auto align-items-end">
              <li className="nav-item">
                <Link to="/" className="nav-link" activeClassName="active">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link" activeClassName="active">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/blog" className="nav-link" activeClassName="active">
                  Blog
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </React.Fragment>
  )
}

export default Navbar
